<template>
    <st-filters-pagination
        :perPage=10
        stateModule="archive/workspaces"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :moreFilters="false"
        :showErase="false"
        :header="$t('ARCHIVE.WORKSPACE.LIST.TITLE')"
        :calcParams="calcArchiveParams"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import calcArchiveParams from '@/shared/utils/calc-archive-query-params';
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';

    const filterSchema = new FilterSchema([]);

    export default {
        name: 'WorkspaceListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                calcArchiveParams
            }
        },
        computed: {
            ...mapGetters({
               total: 'archive/workspaces/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'archive/workspaces/getWorkspaces',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },
    };
</script>
